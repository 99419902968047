/* Publication.css */

/* Style général de la page */
.publication-page {
  background-color: #f2f2f2; /* Gris clair pour le fond */
  color: #000; /* Noir pour le texte */
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

/* En-tête */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #d9d9d9; /* Gris pour l'en-tête */
  border-bottom: 1px solid #ccc;
}

.header .menu-icon,
.header .header-icons svg {
  font-size: 20px;
  color: #000;
  cursor: pointer;
}

.header .logo {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

/* Formulaire de création de publication */
.publication-form {
  margin: 20px;
  padding: 15px;
  background-color: #d9d9d9;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.publication-form textarea {
  width: 100%;
  height: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 14px;
}

.publication-form input[type="file"] {
  display: block;
  margin-bottom: 10px;
}

.publication-form button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.publication-form button:hover {
  background-color: #333;
}

/* Liste des publications */
.publications-list {
  margin: 15px;
}

.publication {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.publication-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.publication-header .profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  border: 2px solid #ccc;
}

.publication-info h3 {
  margin: 0;
  font-size: 16px;
  color: #000;
}

.publication-info h3.clickable-username {
  cursor: pointer;
}

.publication-info span {
  font-size: 12px;
  color: #666;
}

.delete-icon {
  margin-left: auto;
  color: #000;
  cursor: pointer;
}

.delete-icon:hover {
  color: red;
}

/* Contenu des publications */
.media {
  margin-top: 10px;
}

.media img,
.media video {
  max-width: 100%;
  border-radius: 5px;
  margin-top: 10px;
}

/* Pied des publications */
.publication-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.publication-footer button {
  background: none;
  border: none;
  color: #000;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.publication-footer button:hover {
  color: #333;
}

/* Section des commentaires */
.comments-section {
  margin-top: 15px;
}

.comment {
  background-color: #e6e6e6; /* Gris clair pour les commentaires */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.comment-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.comment-header .comment-profile-picture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.comment-username {
  font-weight: bold;
  color: #000;
}

.comment p {
  margin: 0;
  font-size: 14px;
}

.comment-media img,
.comment-media video,
.comment-media audio {
  display: block;
  margin-top: 10px;
  max-width: 100%;
  border-radius: 5px;
}

/* Ajout de commentaire */
.add-comment {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.comment-textarea {
  width: 100%;
  height: 60px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.comment-media-section {
  display: flex;
  gap: 10px;
  align-items: center;
}

.media-icon {
  cursor: pointer;
  font-size: 20px;
  color: #000;
}

.microphone-icon {
  font-size: 20px;
  color: #000;
  cursor: pointer;
}

.microphone-icon.recording {
  color: red;
}

.send-comment-button {
  align-self: flex-end;
  background-color: #000;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.send-comment-button:hover {
  background-color: #333;
}

/* Barre de navigation en bas */
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #d9d9d9;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  transition: transform 0.3s ease-in-out;
}

.bottom-nav.hidden {
  transform: translateY(100%);
}

.bottom-nav.visible {
  transform: translateY(0);
}

.bottom-nav svg {
  font-size: 20px;
  color: #000;
  cursor: pointer;
}

.bottom-nav svg:hover {
  color: #333;
}

/* Modale de partage */
.share-modal {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.share-modal h4 {
  margin: 0 0 10px;
}

.share-modal ul {
  list-style: none;
  padding: 0;
}

.share-modal li {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.share-modal button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.share-modal button:hover {
  background-color: #333;
}
