/* MainNavigation.css */
.main-navigation {
    display: flex;
    height: 100vh;
  }
  
  .sidebar {
    width: 15%;
    background-color: #2c3e50;
    color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .sidebar button {
    background: none;
    border: none;
    color: white;
    padding: 10px;
    margin: 5px 0;
    cursor: pointer;
  }
  
  .dashboard {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ecf0f1;
  }
  
  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .card {
    width: 200px;
    height: 100px;
    background-color: #3498db;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .bottom-nav {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #2c3e50;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
  }
  
  .bottom-nav button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  